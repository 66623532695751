/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

// jQuery
import $ from 'jquery';

// ResponsiveBP
import 'responsive-bp/src/js/responsive.core.js';

import './modules/lite-vimeo/lite-vimeo.js';

// Calculate
var timeout = false,
	timeoutDelay = 250;

function calculateSizes() {
	document.documentElement.style.setProperty('--vh', `${document.documentElement.clientHeight}px`);
	document.documentElement.style.setProperty('--sw', `${window.innerWidth - document.documentElement.clientWidth}px`);
	document.documentElement.style.setProperty('--hh', `${document.getElementsByTagName('header')[0].clientHeight}px`)
}

calculateSizes();

window.addEventListener('resize', function () {
	clearTimeout(timeout);
	timeout = setTimeout(calculateSizes, timeoutDelay);
});


// Wrap element with Swiper elements
var wrapSwiper = function (element, childrenSelector = null, pagination = false, navigation = false) {
	var $element = $(element);
	$element.children(childrenSelector).addClass('swiper-slide');
	$element.wrapInner('<div class="swiper-wrapper" />');

	if (pagination) {
		$element.append('<div class="swiper-pagination" />');
	}

	if (navigation) {
		$element.append('<a class="swiper-button-prev"><i class="icon icon-arrow-left"></i></a>');
		$element.append('<a class="swiper-button-next"><i class="icon icon-arrow-right"></i></a>');
	}
};

$(document).ready(async function () {

	// Responsive menu
	$('.toggler').each(function () {
		$(this).click(toggleMenu);
	});

	var responsiveNav = $('.responsive-nav');
	function toggleMenu() {
		var toggler = $('.toggler');

		$(document.body).toggleClass('menu-open');
		responsiveNav.toggleClass('-open');
		toggler.toggleClass('is-active');
	}

	responsiveNav.find('nav > ul > li > ul > li').each(function () {
		var element = $(this);
		var link = element.children('a').first();
		link.click(function (e) {
			responsiveNav.toggleClass('-open');
			toggler.toggleClass('-is-active');
			e.preventDefault();
		});
	});

	// Masonry
	$('.-masonry').each(async function () {
		const Masonry = await import('masonry-layout');

		new Masonry.default('.-masonry', {
			itemSelector: '.-masonry > div'
		});
	});

	// Tabs
	var tabs = $('.tabs');
	if (tabs.length) {
		await import("../../node_modules/responsive-bp/src/js/responsive.tabs");
	}

	// Slideshow hero
	$('.slideshow .swiper-container').each(async function () {
		const Swiper = await import('swiper/dist/js/swiper.js');
		new Swiper.default(this, {
			loop: true,
			effect: 'fade',
			autoplay: {
				delay: 3000
			},
			speed: 1500
		});
	});

	// Slideshow quotes
	$('.slider--quotes').each(async function () {
		const Swiper = await import('swiper/dist/js/swiper.js');
		var $this = $(this);

		wrapSwiper($this, '.swiper-slide', false, false);

		// Remove block row classes
		var blockClasses = $.map(['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl'], function (bp) {
			return $.map([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], function (cols) {
				return 'block-row-' + bp + '-' + cols;
			}).join(' ');
		}).join(' ');
		$this.removeClass(blockClasses);


		new Swiper.default(this, {
			loop: true,
			effect: 'slide',
			autoplay: {
				delay: 7500
			},
			speed: 1500,
			spaceBetween: 20,
			slidesPerView: 1,
			keyboard: true,
			breakpointsInverse: true,
			breakpoints: {
				640: {
					slidesPerView: 2
				},
				1280: {
					slidesPerView: 3 
				}
			}
		});
	});

	// Slideshow logos
	$('.slider--logos').each(async function () {
		const Swiper = await import('swiper/dist/js/swiper.js');
		var $this = $(this);

		wrapSwiper($this, '.swiper-slide', false, false);

		// Remove block row classes
		var blockClasses = $.map(['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl'], function (bp) {
			return $.map([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], function (cols) {
				return 'block-row-' + bp + '-' + cols;
			}).join(' ');
		}).join(' ');
		$this.removeClass(blockClasses);


		new Swiper.default(this, {
			loop: true,
			effect: 'slide',
			autoplay: {
				delay: 7500
			},
			speed: 1500,
			spaceBetween: 48,
			slidesPerView: "auto",
			keyboard: true,
			breakpointsInverse: true
		});
	});

	// Slideshow arrangements
	$('.slider--arrangements').each(async function () {
		const Swiper = await import('swiper/dist/js/swiper.js');
		var $this = $(this);

		wrapSwiper($this, '.swiper-slide', false, true);

		// Remove block row classes
		var blockClasses = $.map(['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl'], function (bp) {
			return $.map([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], function (cols) {
				return 'block-row-' + bp + '-' + cols;
			}).join(' ');
		}).join(' ');
		$this.removeClass(blockClasses);


		new Swiper.default(this, {
			loop: true,
			effect: 'slide',
			autoplay: {
				delay: 10000
			},
			speed: 1500,
			spaceBetween: 20,
			slidesPerView: 1,
			keyboard: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			},
			breakpointsInverse: true,
			breakpoints: {
				768: {
					slidesPerView: 2
				},
				1280: {
					slidesPerView: 3
				}
			}
		});
	});

	// Lightbox
	$('body:has([rel=lightbox])').each(async function () {
		await import('lightgallery');
		await import('lg-video');

		$(this).lightGallery({
			selector: '[rel=lightbox]',
			download: false,
			thumbnail: false,
			fullScreen: false,
			actualSize: false,
			hash: false
		});
	});

	// Dropdown
	$(".-accordion").each(async function () {
		await import("../../node_modules/responsive-bp/src/js/responsive.core.js");
		await import("../../node_modules/responsive-bp/src/js/responsive.dropdown.js");

		// Fix for link in the dropdown part
		$(this).find('a').on('click', function (event) { event.stopPropagation(); });
	});

	//// Smooth scroll
	//$('a[href*=\\#]').click(function () {
	//	var target = $(this.hash);

	//	if (!target.length) {
	//		target = $('[name=' + this.hash.slice(1) + ']');
	//	}

	//	if (target.length) {
	//		$('html, body').animate({
	//			scrollTop: target.offset().top
	//		}, 250);

	//		return false;
	//	}
	//});

	// Jump to submitted form
	$('.umbraco-forms-form[id]:has(form)').each(function () {
		var $this = $(this),
			form = $this.find('form'),
			button = $this.find('.btn');

		form.attr('action', form.attr('action') + '#' + $this.attr('id'));

		console.log(button);

		form.submit(function (event) {
			button.removeClass('-loading').addClass('-loading');
		});
	});

	// Change target for external sites
	var getDomainName = function (hostName) {
		return hostName.substring(hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1);
	};
	$('a:not([target])').filter(function () {
		var link = $(this);

		if (link.filter('[rel~="external"]').length) return true; // Link rel attribute contains 'external'

		var href = link.attr('href');
		if (new RegExp('\.pdf$', 'i').test(href)) return true; // Link to PDF file

		if (new RegExp('^https?://', 'i').test(href)) {
			var linkHostName = getDomainName(href.substring(href.indexOf('/') + 2, href.indexOf('/', 8)));
			if (!new RegExp('(^|\.)' + getDomainName(document.location.hostname) + '$', 'i').test(linkHostName)) {
				// Link is to different domain name
				return true;
			}
		}

		return false;
	}).attr('target', '_blank');
});